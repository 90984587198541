export const searchParamsHelper = (params: {
  [key: string]: string | string[] | undefined;
}): string => {
  let queryStringParts: Array<string> = [];

  for (const key in params) {
    if (Object.prototype.hasOwnProperty.call(params, key)) {
      const value = params[key];
      if (Array.isArray(value)) {
        for (let i = 0; i < value.length; i++) {
          queryStringParts.push(`${key}=${value[i]}`);
        }
      } else {
        queryStringParts.push(`${key}=${value || ''}`);
      }
    }
  }
  return queryStringParts.join('&');
};
