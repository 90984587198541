'use client';
import { BannerImageEntityMobile } from '@entities/mobile/banner-image';
import {
  CardItemShopEntityMobile,
  CardLiveFeedEntityMobile,
  CardMarketEntityMobile,
  CardShoppingItemEntityMobile,
} from '@entities/mobile/cards';
import { AddToCartFeatureMobile } from '@features/mobile/add-to-cart';
import { AddToFavoriteFeatureMobile } from '@features/mobile/add-to-favorite';
import { SelectPetPropertiesFeature } from '@features/select-pet-properties';
import { useQueryString } from '@hooks/client';
import {
  BannerVariantWithAction,
  ContentBannerProps,
  ContentWidgetCategoriesProps,
  ContentWidgetItemsBlockProps,
  ContentWidgetLiveFeedProps,
} from '@widgets/mobile/content/ui/content.types';
import cn from 'clsx';
import { observer } from 'mobx-react-lite';
import Image from 'next/image';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { Autoplay, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useMediaQuery } from 'usehooks-ts';
import { useIsClient } from 'usehooks-ts';

import { tabs1, tabs2 } from '@/mocks/header-tabs';
import { mockedData } from '@/mocks/items';
import { mockedCategoriesMobile1 } from '@/mocks/market-categories';
import { SeeAllButton } from '@/shared/components/see-all-button';
import { WithShopItems } from '@/shared/hocs';
import { Portal } from '@/shared/portal';
import { GlobalItem } from '@/shared/types/common';
import { Illustration, Label, Skeleton } from '@/shared/ui';
import { Plate } from '@/shared/ui/desktop/plate';
import { AdditionalInfoMobile } from '@/shared/ui/mobile/additional-info';
import { BottomSheetUi } from '@/shared/ui/mobile/bottom-sheet';
import { Tabs } from '@/shared/ui/tabs';
import { CartItemType } from '@/store/cart/cart';
import { useStore } from '@/store/context';

import styles from './content.module.scss';
import swiperStyles from './swiper.module.scss';

export const ContentWidgetMobile = () => {
  const isClient = useIsClient();

  const auth = useStore().authorization;

  const searchParams = useSearchParams();

  const router = useRouter();

  const isMediumScreen = useMediaQuery('(min-width: 428px)');

  const topBannersEndRef = useRef<HTMLDivElement | null>(null);

  const screenSize = isClient ? (isMediumScreen ? 'large' : 'small') : null;

  const promocode = searchParams.get('promocode');

  const scrollToItems = () => {
    topBannersEndRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  const topBanners: BannerVariantWithAction[] = [
    {
      variant: 'pets-giveaway',
      pressAction: () => {},
    },
    {
      variant: 'concept1',
      pressAction: scrollToItems,
    },
    {
      variant: 'concept2',
      pressAction: () => router.push('/shop'),
    },
    {
      variant: 'concept3',
      pressAction: scrollToItems,
    },
  ];
  const bottomBanners: BannerVariantWithAction[] = [
    {
      variant: 'bloggers-collaboration',
      pressAction: () => {},
    },
    {
      variant: 'constant-promotion',
      pressAction: () => {},
    },
    {
      variant: 'social-boost',
      pressAction: () => {},
    },
  ];

  useEffect(() => {
    if (promocode) {
      router.push('/');
      auth.openPromoLanding();
    }
  });

  return (
    <div className={styles['content-widget__container']}>
      <div className={styles['wrapper']}>
        <div>
          <ContentWidgetMobile.Banner
            banners={topBanners}
            screenSize={screenSize}
            sliderSize={'l'}
          />
          <div ref={topBannersEndRef} />
        </div>
        <ContentWidgetMobile.LiveFeed items={mockedData} />
        <WithShopItems>
          {({ items }) => {
            return (
              <ContentWidgetMobile.ItemsBlock
                items={items}
                headerSlot={
                  <>
                    <Label
                      labelVariant={'red'}
                      icon={'fire-square-red'}
                      variant={'primary'}
                    >
                      Hot Sales
                    </Label>
                    <SeeAllButton
                      href={'/shop?sort=popular'}
                      variant={'secondary'}
                    />
                  </>
                }
              />
            );
          }}
        </WithShopItems>

        <ContentWidgetMobile.Banner
          banners={[...bottomBanners, ...bottomBanners]}
          screenSize={screenSize}
          sliderSize={'m'}
        />

        <ContentWidgetMobile.Categories categories={mockedCategoriesMobile1} />

        <WithShopItems>
          {({ items }) => {
            return (
              <ContentWidgetMobile.ItemsBlock
                items={items}
                headerSlot={
                  <>
                    <Label
                      labelVariant={'black'}
                      icon={'star-circle'}
                      variant={'primary'}
                    >
                      Exclusive Items
                    </Label>
                    <SeeAllButton
                      href={'/shop?sort=popular'}
                      variant={'secondary'}
                    />
                  </>
                }
              />
            );
          }}
        </WithShopItems>

        <WithShopItems>
          {({ items }) => {
            return (
              <ContentWidgetMobile.ItemsBlock
                items={items}
                headerSlot={
                  <>
                    <Label
                      labelVariant={'yellow'}
                      icon={'graph-up'}
                      variant={'primary'}
                    >
                      Popular items
                    </Label>
                    <SeeAllButton
                      href={'/shop?sort=popular'}
                      variant={'secondary'}
                    />
                  </>
                }
              />
            );
          }}
        </WithShopItems>
      </div>
      <ContentWidgetMobile.Tabs />
    </div>
  );
};

// eslint-disable-next-line react/display-name
ContentWidgetMobile.Banner = ({
  className,
  sliderSize,
  banners,
  screenSize,
}: ContentBannerProps) => {
  const bannerClasses = cn(styles['content-widget__banner'], className);
  const bannerSlideClasses = cn(
    swiperStyles['slide'],
    swiperStyles[`slide-${sliderSize}`],
  );

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isLargeMobile = useMediaQuery('(min-width: 744px)');

  const swiperConfig = isLargeMobile
    ? {
      centeredSlides: true,
      slidesPerView: 1.7,
    }
    : {
      slidesPerView: 1,
    };

  if (!screenSize) {
    return (
      <div className={bannerClasses}>
        <Plate className={swiperStyles.plate} />
      </div>
    );
  }

  return (
    <div className={bannerClasses}>
      <Swiper
        pagination={{
          clickable: true,
        }}
        loop
        autoplay
        {...swiperConfig}
        modules={[Autoplay, Pagination]}
        className={'swiper-mobile'}
      >
        {banners.map((banner, idx) => {
          return (
            <SwiperSlide key={`${idx}-src-mobile-slide`}>
              <div className={bannerSlideClasses} onClick={banner.pressAction}>
                <BannerImageEntityMobile
                  variant={banner.variant}
                  size={screenSize}
                />
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

// eslint-disable-next-line react/display-name
ContentWidgetMobile.LiveFeed = ({ items }: ContentWidgetLiveFeedProps) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [sheetOpened, setSheetOpened] = useState(false);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [selectedItem, setSelectedItem] = useState<GlobalItem | null>(null);

  const toggleSheet = (item: GlobalItem | null) => {
    setSheetOpened(prev => !prev);
    setSelectedItem(item);
  };

  const itemprice = 3;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const router = useRouter();

  return (
    <div className={styles['content-widget__live-feed-container']}>
      <Label
        className={styles['label']}
        labelVariant={'purple'}
        icon={'graph-new-up'}
        variant={'primary'}
      >
        Live Sales
      </Label>
      <div className={styles['content-widget__live-feed']}>
        {Array.isArray(items) &&
          items.length > 0 &&
          items?.map((item, idx) => (
            <CardLiveFeedEntityMobile
              handleInfoClick={() => toggleSheet(item)}
              key={`${idx}-${item.info.title}-${item?.info?.price?.old}`}
              item={item.item}
              rarity={item.rarity}
            />
          ))}
      </div>

      <BottomSheetUi isOpen={sheetOpened} handleClose={() => toggleSheet(null)}>
        <BottomSheetUi.Content>
          <BottomSheetUi.Header>About purchase</BottomSheetUi.Header>

          <div className={styles['content-widget__live-feed-sheet-info']}>
            {selectedItem && (
              <CardShoppingItemEntityMobile
                showAge
                itemPriceSlot={
                  <CardShoppingItemEntityMobile.ItemPrice
                    variant={'primary'}
                    status={'available'}
                    info={selectedItem?.info}
                  />
                }
                variant={'primary'}
                itemPropertiesSlot={
                  <CardShoppingItemEntityMobile.ItemProperties
                    rarity={selectedItem.rarity}
                    tagCategory={selectedItem.tagCategory}
                  />
                }
                info={selectedItem?.info}
                itemImageSlot={
                  <CardShoppingItemEntityMobile.ItemImageSlot
                    item={selectedItem.item}
                    rarity={selectedItem.rarity}
                    status={'available'}
                  />
                }
                rarity={selectedItem.rarity}
                //topRightSlot={<AddToCartShoppingItemFeatureMobile item={null} />}
                //bottomLeft={<AdjustItemQuantityFeatureMobile itemId={''}/>}
                //bottomRight={<><AddToFavoriteShoppingItemFeatureMobile itemId={''} /><RemoveItemFeatureMobile itemId={''}/></>}
              />
            )}
            <AdditionalInfoMobile
              variant={'success'}
              icon={
                <Illustration
                  style={{ color: 'green' }}
                  name={'tag-price'}
                  spriteName={'icons'}
                  spanTagClassName={styles.icon}
                />
              }
            >
              Item price:{' '}
              <ContentWidgetMobile.WithCurrency>
                {selectedItem?.info.price.current}
              </ContentWidgetMobile.WithCurrency>
            </AdditionalInfoMobile>
          </div>
          <div className={styles['content-widget__live-feed-sheet-btns']}>
            <BottomSheetUi.Button
              onClick={
                selectedItem
                  ? () =>
                    router.push(
                      `/shop/${selectedItem?.category}/${selectedItem?.id}`,
                    )
                  : () => {}
              }
              text={'Go to item'}
              variant={'primary'}
              iconRight={
                <Illustration
                  id={'btn-icon'}
                  name={'square-arrow-right'}
                  spriteName={'icons'}
                />
              }
            />
            <BottomSheetUi.Button
              text={'Close'}
              variant={'secondary'}
              onClick={() => toggleSheet(null)}
            />
          </div>
        </BottomSheetUi.Content>
      </BottomSheetUi>
    </div>
  );
};

ContentWidgetMobile.WithCurrency = observer(
  ({ children }: { children: ReactNode }) => {
    const currencySign = useStore().app.getCurrencySign;

    return `${currencySign} ${children}`;
  },
);

// eslint-disable-next-line react/display-name
ContentWidgetMobile.ItemsBlock = ({
  headerSlot,
  items,
}: ContentWidgetItemsBlockProps) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const router = useRouter();

  return (
    <div className={styles['content-widget__items-block']}>
      {headerSlot && <div className={styles['header']}>{headerSlot}</div>}
      <div className={styles['items']}>
        {items?.map((item, idx) => {
          let itemReadyToCart: CartItemType = {
            ...item,
            status: 'available',
            quantity: 1,
          };

          return (
            <CardItemShopEntityMobile
              onClick={() => router.push(`/shop/${item.category}/${item?.id}`)}
              key={`card-${idx}-${item.info.title}-${item?.info?.price?.old}`}
              item={item.item}
              rarity={item.rarity}
              info={{
                title: item?.info?.title,
                price: {
                  old: item?.info?.price?.old,
                  current: item?.info?.price?.current,
                },
              }}
              propertiesSlot={<SelectPetPropertiesFeature item={item} />}
              tagsInfo={item?.tagsInfo}
              topRightSlot={
                <AddToFavoriteFeatureMobile variant={'primary'} id={item.id} />
              }
              bottomSlot={<AddToCartFeatureMobile item={itemReadyToCart} />}
            />
          );
        })}
      </div>
    </div>
  );
};

// eslint-disable-next-line react/display-name
ContentWidgetMobile.Categories = ({
  categories,
}: ContentWidgetCategoriesProps) => {
  return (
    <div className={styles['content-widget__categories']}>
      {categories?.map(({ id, ...category }, idx) => {
        return (
          <CardMarketEntityMobile
            key={`mobile-${idx}-${category.title}-${category.item}`}
            {...category}
          />
        );
      })}
    </div>
  );
};

// eslint-disable-next-line react/display-name
ContentWidgetMobile.Tabs = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const router = useRouter();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const pathname = usePathname();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { createQueryString } = useQueryString();

  return (
    <Portal selector={'#header-portal'}>
      <Tabs
        onTabsChange={() => {}}
        defaultActiveTab={'Hot Sale'}
        className={styles.tab_container}
      >
        <Tabs.Item
          value={'Hot Sale'}
          variant="secondary"
          onClick={() =>
            router.push(
              createQueryString({
                pathname: '/shop',
                name: 'sale',
                value: 'hot-sale',
                asMultipleParams: true,
              }),
            )
          }
          iconsLeft={
            <Illustration
              name={'fire-square'}
              spriteName={'icons'}
              size={20}
              style={{ color: '#EB3C3C' }}
            />
          }
        >
          Hot Sale
        </Tabs.Item>
        <Tabs.Item
          value={'New'}
          variant="tertiary"
          onClick={() =>
            router.push(
              createQueryString({
                pathname: '/shop',
                name: 'category',
                value: 'new',
                asMultipleParams: true,
              }),
            )
          }
          iconsLeft={
            <Illustration
              name={'lightning'}
              spriteName={'icons'}
              size={20}
              style={{ color: '#40BB18' }}
            />
          }
        >
          New
        </Tabs.Item>
        {tabs2.map((tab, idx) => (
          <Tabs.Item
            key={`header-${idx}-${tab.name}-1`}
            value={tab.name}
            onClick={() =>
              router.push(
                createQueryString({
                  pathname: '/shop',
                  name: 'category',
                  value: tab.name.toLowerCase(),
                  asMultipleParams: false,
                }),
              )
            }
            variant="primary"
          >
            {tab.name}
          </Tabs.Item>
        ))}
        {tabs1.map((tab, idx) => (
          <Tabs.Item
            key={`header-${idx}-${tab.name}-1`}
            value={tab.name}
            onClick={() =>
              router.push(
                createQueryString({
                  pathname: '/shop',
                  name: 'property',
                  value: tab.name.toLowerCase(),
                  enableToggle: false,
                }),
              )
            }
            tagCategory={tab.icon}
            variant="primary"
          >
            {tab.name}
          </Tabs.Item>
        ))}
      </Tabs>
    </Portal>
  );
};
