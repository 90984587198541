import cn from 'clsx';
import { motion } from 'framer-motion';
import React, { SyntheticEvent } from 'react';

import { Illustration } from '@/shared/ui';

import styles from './button-favorite.module.scss';
import { ButtonFavoriteProps } from './types';

export const ButtonFavoriteMobile: React.FC<ButtonFavoriteProps> = ({
  isLiked,
  onPress,
  variant,
}) => {
  const containerClasses = cn(styles.container, styles[`container-${variant}`]);

  const handleClick = (event: SyntheticEvent) => {
    event.stopPropagation();
    onPress();
  };

  return (
    <motion.button
      whileTap={{ scale: 1.1 }}
      className={containerClasses}
      onClick={handleClick}
    >
      <Illustration
        spanTagClassName={cn(styles['btn'], {
          [styles['btn-active']]: isLiked,
        })}
        name={
          isLiked || variant === 'secondary'
            ? 'button-favorite-filled'
            : 'button-favorite-outline'
        }
        spriteName={'icons'}
      />
    </motion.button>
  );
};
