import cn from 'clsx';

import { CardItemInfo } from '@/shared/ui/cards';

import stylesInfo from './bottom-bar-item-info.module.scss';
import styles from './bottom-bar-item-page.module.scss';
import {
  BottomBarItemPageInfoProps,
  BottomBarItemPageProps,
} from './bottom-bar-item-page.types';

export const BottomBarItemPageEntityMobile = ({
  className,
  topLeftSlot,
  topRightSlot,
  bottomLeftSlot,
  bottomRightSlot,
}: BottomBarItemPageProps) => {
  const bottomBarClasses = cn(styles['container'], className);

  return (
    <div className={bottomBarClasses}>
      <div className={styles['content']}>
        <div className={styles['top-slot']}>
          {topLeftSlot}
          {topRightSlot}
        </div>
        {(bottomLeftSlot || bottomRightSlot) && (
          <div className={styles['bottom-slot']}>
            {bottomLeftSlot}
            {bottomRightSlot}
          </div>
        )}
      </div>
    </div>
  );
};

// eslint-disable-next-line react/display-name
BottomBarItemPageEntityMobile.Info = ({ info }: BottomBarItemPageInfoProps) => {
  return <CardItemInfo customStyles={stylesInfo} info={info} />;
};
