'use client';
import { observer } from 'mobx-react-lite';
import { useIsClient } from 'usehooks-ts';

import { AdvertisingEntity } from '@/entities';
import { AdvertisingCloseButton } from '@/shared/components/advertising-close-button';
import { Typography } from '@/shared/ui';
import { useStore } from '@/store/context';

import styles from './advertising.module.scss';
export const AdvertisingWidgetMobile = observer(() => {
  const advStore = useStore()?.advertising;
  const isClient = useIsClient();

  if (!advStore?.showHeaderAdvBlock || !isClient) {
    return null;
  }

  return (
    <AdvertisingEntity
      className={styles['advertising-mobile']}
      middleSlot={
        <div className={styles['content']}>
          <Typography className={styles['text']}>
            Get a 5% deposit bonus
          </Typography>
        </div>
      }
      rightSlot={
        <AdvertisingCloseButton
          onClick={() => advStore?.toggleShowHeaderAdvBlock()}
        />
      }
    />
  );
});
