import cn from 'clsx';
import React, { ComponentProps, ReactNode } from 'react';

import { DivWithChildrenProps } from '@/shared/types/common';
import { Badge, Illustration, Typography } from '@/shared/ui';

import styles from './navigation-bottom.module.scss';
import { NavigationBottomProps } from './navigation-bottom.types';
export const NavigationBottomEntityMobile = ({
  className,
  children,
}: NavigationBottomProps) => {
  const navClasses = cn(styles['container'], className);

  return (
    <nav className={navClasses}>
      <div className={styles['tabs-container']}>{children}</div>
    </nav>
  );
};
type NavigationItemProps = DivWithChildrenProps & {
  topSlot: ReactNode;
  bottomSlot: ReactNode;
  withBadge?: boolean;
  isActive: boolean;
  badgeCount?: number;
};

// eslint-disable-next-line react/display-name
NavigationBottomEntityMobile.Item = ({
  topSlot,
  withBadge,
  bottomSlot,
  className,
  isActive,
  badgeCount,
  ...props
}: NavigationItemProps) => {
  const badgeStyles = cn(
    styles['badge'],
    { [styles['one-number']]: badgeCount && badgeCount < 10 },
    { [styles['two-numbers']]: badgeCount && badgeCount >= 10 },
    { [styles['three-numbers']]: badgeCount && badgeCount > 99 },
  );

  return (
    <div
      className={cn(
        styles['item'],
        { [styles['active']]: isActive },
        className,
      )}
      {...props}
    >
      <div className={styles['top-slot']}>
        {topSlot}
        {withBadge && (
          <Badge count={badgeCount} className={badgeStyles} isMobile />
        )}
      </div>
      {bottomSlot}
    </div>
  );
};

// eslint-disable-next-line react/display-name
NavigationBottomEntityMobile.Title = ({
  children,
}: {
  children: ReactNode;
}) => {
  return (
    <Typography className={styles['bottom-slot--title']}>{children}</Typography>
  );
};

// eslint-disable-next-line react/display-name
NavigationBottomEntityMobile.Icon = ({
  icon,
}: {
  icon: ComponentProps<typeof Illustration>['name'];
}) => {
  return (
    <Illustration
      name={icon}
      spriteName={'icons'}
      spanTagClassName={styles['bottom-slot--icon']}
    />
  );
};
