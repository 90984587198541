import { motion } from 'framer-motion';

import { Button, Illustration } from '@/shared/ui';

import styles from './apply.module.scss';

export const ApplyFiltersFeatureMobile = () => {
  return (
    <Button
      as={motion.button}
      whileTap={{ scale: 0.98 }}
      variant={'primary'}
      text={'Apply'}
      className={styles['feature']}
      iconRight={
        <Illustration
          spanTagClassName={styles['feature-icon']}
          spriteName={'icons'}
          name={'check-square'}
        />
      }
    />
  );
};
