import {
  TablePurchasesRows,
  TableTopUpRows,
  TableWithdrawRows,
} from '@widgets/desktop/profile-history/ui/profile-history.types';
import cn from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { ComponentProps } from 'react';

import {
  DivWithChildrenProps,
  ItemAge,
  ItemRarityVariants,
} from '@/shared/types/common';
import {
  Illustration,
  Item,
  ItemRarityCard,
  TagCategory,
  Typography,
} from '@/shared/ui';
import { ItemVariant } from '@/shared/ui/item/item.types';
import { TagCategoryVariants } from '@/shared/ui/tag-category/tag-category.types';
import { useStore } from '@/store/context';

import styles from './card-history.module.scss';
import { TagInfoProps, TagInfoTextProps } from './card-history.types';

const statuses = {
  0: 'danger',
  1: 'warn',
  2: 'success',
};

export const CardHistoryEntityMobile = ({
  children,
  className,
  ...props
}: DivWithChildrenProps) => {
  return (
    <div className={cn(styles['container'], className)} {...props}>
      {children}
    </div>
  );
};

// eslint-disable-next-line react/display-name
CardHistoryEntityMobile.Purchase = observer(
  ({
    children,
    row,
    className,
    ...props
  }: DivWithChildrenProps & { row: TablePurchasesRows }) => {
    const notificationStore = useStore()?.notifications;
    const handleCopyId = () => {
      navigator.clipboard
        .writeText(String(row.id))
        .then(() => {
          notificationStore?.addNotification({
            variant: 'success',
            title: 'Copied!',
            subtitle: `ID: ${String(row.id)}`,
            delay: 3,
            id: '1-copy',
          });
        })
        .catch(() => {
          notificationStore?.addNotification({
            variant: 'error',
            title: 'Something went wrong...',
            subtitle: 'Unable to copy',
            delay: 3,
            id: '2-copy',
          });
        });
    };

    return (
      <div className={cn(styles['container'], className)} {...props}>
        <Slot>
          <HistoryTag>
            <HistoryTag.Text>{row.type}</HistoryTag.Text>
          </HistoryTag>

          <HistoryTag>
            <HistoryTag.Text>{row.date}</HistoryTag.Text>
          </HistoryTag>
        </Slot>

        <Slot>
          <ItemContent>
            <ItemContent.ItemAndAge>
              <ItemContent.Item item={row.item.item} rarity={row.item.rarity} />
              <ItemContent.Info
                info={{
                  title: row.item.name,
                  age: row.item.age,
                }}
              />
            </ItemContent.ItemAndAge>
          </ItemContent>
          <ItemContent.Properties properties={row.properties} />
        </Slot>

        <Slot>
          <HistoryTag>
            <HistoryTag.Text>$ {row.price}</HistoryTag.Text>
          </HistoryTag>
          <HistoryTag onClick={() => handleCopyId()}>
            <HistoryTag.Text>ID: {row.id}</HistoryTag.Text>
            <Illustration
              name={'copy'}
              size={16}
              spriteName={'icons'}
              style={{ color: '#BFBFBF' }}
            />
          </HistoryTag>
        </Slot>
      </div>
    );
  },
);

// eslint-disable-next-line react/display-name
CardHistoryEntityMobile.Withdraw = observer(
  ({
    children,
    row,
    className,
    ...props
  }: DivWithChildrenProps & { row: TableWithdrawRows }) => {
    const notificationStore = useStore()?.notifications;
    const handleCopyId = () => {
      navigator.clipboard
        .writeText(String(row.id))
        .then(() => {
          notificationStore?.addNotification({
            variant: 'success',
            title: 'Copied!',
            subtitle: `ID: ${String(row.id)}`,
            delay: 3,
            id: '1-copy',
          });
        })
        .catch(() => {
          notificationStore?.addNotification({
            variant: 'error',
            title: 'Something went wrong!',
            subtitle: 'Unable to copy',
            delay: 3,
            id: '2-copy',
          });
        });
    };

    return (
      <div className={cn(styles['container'], className)} {...props}>
        <Slot>
          <HistoryTag>
            <HistoryTag.Text>Nick: {row.nickname}</HistoryTag.Text>
          </HistoryTag>

          <HistoryTag
            variant={
              statuses[row.status.code] as ComponentProps<
                typeof HistoryTag
              >['variant']
            }
          >
            <HistoryTag.Text
              variant={
                statuses[row.status.code] as ComponentProps<
                  typeof HistoryTag
                >['variant']
              }
            >
              {row.status.text}
            </HistoryTag.Text>
          </HistoryTag>
        </Slot>

        <Slot>
          <ItemContent>
            <ItemContent.ItemAndAge>
              <ItemContent.Item item={row.item.item} rarity={row.item.rarity} />
              <ItemContent.Info
                info={{
                  title: row.item.name,
                  age: row.item.age,
                }}
              />
            </ItemContent.ItemAndAge>
          </ItemContent>
          <ItemContent.Properties properties={row.properties} />
        </Slot>

        <Slot>
          <HistoryTag>
            <HistoryTag.Text>{row.date}</HistoryTag.Text>
          </HistoryTag>

          <HistoryTag width={'content'}>
            <HistoryTag.Text>$ {row.price}</HistoryTag.Text>
          </HistoryTag>

          <HistoryTag onClick={() => handleCopyId()}>
            <HistoryTag.Text>ID: {row.id}</HistoryTag.Text>
            <Illustration
              name={'copy'}
              size={16}
              spriteName={'icons'}
              style={{ color: '#BFBFBF' }}
            />
          </HistoryTag>
        </Slot>
      </div>
    );
  },
);

// eslint-disable-next-line react/display-name
CardHistoryEntityMobile.TopUp = observer(
  ({
    children,
    row,
    className,
    ...props
  }: DivWithChildrenProps & { row: TableTopUpRows }) => {
    const notificationStore = useStore()?.notifications;
    const handleCopyId = () => {
      navigator.clipboard
        .writeText(String(row.id))
        .then(() => {
          notificationStore?.addNotification({
            variant: 'success',
            title: 'Copied!',
            subtitle: `ID: ${String(row.id)}`,
            delay: 3,
            id: '1-copy',
          });
        })
        .catch(() => {
          notificationStore?.addNotification({
            variant: 'error',
            title: 'Something went wrong!',
            subtitle: 'Unable to copy',
            delay: 3,
            id: '2-copy',
          });
        });
    };

    return (
      <div className={cn(styles['container'], className)} {...props}>
        <Slot>
          <HistoryTag
            variant={
              statuses[row.status.code] as ComponentProps<
                typeof HistoryTag
              >['variant']
            }
          >
            <HistoryTag.Text
              variant={
                statuses[row.status.code] as ComponentProps<
                  typeof HistoryTag
                >['variant']
              }
            >
              {row.status.text}
            </HistoryTag.Text>
          </HistoryTag>

          <HistoryTag>
            <HistoryTag.Text>Method: {row.method}</HistoryTag.Text>
          </HistoryTag>
        </Slot>

        <Slot>
          <HistoryTag>
            <HistoryTag.Text>{row.date}</HistoryTag.Text>
          </HistoryTag>

          <HistoryTag width={'content'}>
            <HistoryTag.Text>$ {row.total}</HistoryTag.Text>
          </HistoryTag>

          <HistoryTag onClick={() => handleCopyId()}>
            <HistoryTag.Text>ID: {row.id}</HistoryTag.Text>
            <Illustration
              name={'copy'}
              size={16}
              spriteName={'icons'}
              style={{ color: '#BFBFBF' }}
            />
          </HistoryTag>
        </Slot>
      </div>
    );
  },
);

const ItemContent = ({
  children,
  className,
  ...props
}: DivWithChildrenProps) => {
  return (
    <div className={cn(styles['item-content'], className)} {...props}>
      {children}
    </div>
  );
};

const Slot = ({ children }: DivWithChildrenProps) => {
  return <div className={styles['slot']}>{children}</div>;
};

// eslint-disable-next-line react/display-name
ItemContent.Item = ({
  item,
  rarity,
}: {
  item: ItemVariant;
  rarity: ItemRarityVariants;
}) => {
  return (
    <div className={styles['item-container']}>
      <ItemRarityCard
        className={styles['background']}
        size={'50'}
        variant={rarity}
      />
      <Item className={styles['item']} size={'42'} variant={item} />
    </div>
  );
};
// eslint-disable-next-line react/display-name
ItemContent.ItemAndAge = ({ children }: DivWithChildrenProps) => {
  return <div className={styles['item-age']}>{children}</div>;
};

// eslint-disable-next-line react/display-name
ItemContent.Properties = ({
  properties,
}: {
  properties: TagCategoryVariants[];
}) => {
  if (properties.length <= 0) {
    return null;
  }

  return (
    <div className={styles['properties']}>
      {properties?.map((tag, idx) => {
        return (
          <TagCategory key={`profile-history-${tag}-${idx}`} variant={tag} />
        );
      })}
    </div>
  );
};
// eslint-disable-next-line react/display-name
ItemContent.Info = ({ info }: { info: { title: string; age?: ItemAge } }) => {
  return (
    <div className={styles['item-info']}>
      {info?.age && (
        <Typography className={styles['age']}>{info.age}</Typography>
      )}

      <Typography className={styles['title']}>{info.title}</Typography>
    </div>
  );
};

const HistoryTag = ({
  children,
  width = 'full-w',
  className,
  variant = 'default',
  ...props
}: TagInfoProps) => {
  const tagInfoClasses = cn(
    styles['tag-info'],
    styles[`tag-info--${width}`],
    styles[`tag-info-variant--${variant}`],
    className,
  );

  return (
    <div className={tagInfoClasses} {...props}>
      {children}
    </div>
  );
};

// eslint-disable-next-line react/display-name
HistoryTag.Text = ({
  children,
  className,
  variant = 'default',
}: TagInfoTextProps) => {
  return (
    <Typography
      className={cn(
        styles['text'],
        styles[`text-variant--${variant}`],
        className,
      )}
    >
      {children}
    </Typography>
  );
};
