'use client';
import cn from 'clsx';
import { observer } from 'mobx-react-lite';
import { useIsClient } from 'usehooks-ts';

import { GlobalItem } from '@/shared/types/common';
import {
  Item,
  ItemRarityCard,
  Skeleton,
  TagCategory,
  TagRarity,
  Typography,
} from '@/shared/ui';
import { CartItemType } from '@/store/cart/cart';
import { useStore } from '@/store/context';

import styles from './card-shopping-item.module.scss';
import { CardShoppingItemEntityProps } from './card-shopping-item.types';
export const CardShoppingItemEntityMobile = ({
  className,
  rarity,
  topRightSlot = null,
  bottomRight,
  bottomLeft,
  info,
  showPrice = false,
  showAge = false,
  variant = 'primary',
  disabled,
  size,
  itemImageSlot,
  itemPriceSlot,
  itemPropertiesSlot,
  ...props
}: CardShoppingItemEntityProps) => {
  const cardShoppingItemClasses = cn(
    styles['container'],
    { [styles['without-bottom-slots']]: !bottomRight && !bottomLeft },
    { [styles['disabled']]: disabled },
    { [styles[`size--${size}`]]: size },
    className,
  );

  const currencySign = useStore().app.getCurrencySign;
  const isClient = useIsClient();
  const isHydrated = useStore().app.isHydrated && isClient;

  return (
    <article className={cardShoppingItemClasses} {...props}>
      <div
        className={cn(styles['top-slot'], {
          [styles['with-bottom-slot']]: bottomRight || bottomLeft,
        })}
      >
        <div className={styles['top-slot__left']}>
          {itemImageSlot}

          {isHydrated ? (
            <div
              className={cn(styles['item-info'], {
                [styles['secondary']]: variant === 'secondary',
              })}
            >
              {variant === 'primary' && showPrice && (
                <>
                  {info?.title && (
                    <Typography className={styles['title']}>
                      {info.title}
                    </Typography>
                  )}
                  <Typography
                    style={{ textTransform: 'capitalize' }}
                    className={styles['age']}
                  >
                    {info.age}
                  </Typography>
                  {itemPriceSlot}
                </>
              )}

              {variant === 'secondary' && (
                <>
                  {itemPriceSlot}
                  {info?.title && (
                    <Typography
                      className={cn(styles['title'], styles['secondary'])}
                    >
                      {info.title}
                    </Typography>
                  )}
                </>
              )}
            </div>
          ) : (
            <Skeleton style={{ width: '100px', marginLeft: 20 }} />
          )}
        </div>
        <div className={styles['top-slot__right']}>
          {itemPropertiesSlot}

          {topRightSlot}
        </div>
      </div>
      {variant === 'primary' && (bottomLeft || bottomRight) && (
        <div className={styles['bottom-slot']}>
          {bottomLeft && (
            <div className={styles['bottom-slot__left']}>{bottomLeft}</div>
          )}
          {bottomRight && (
            <div className={styles['bottom-slot__right']}>{bottomRight}</div>
          )}
        </div>
      )}
    </article>
  );
};

CardShoppingItemEntityMobile.ItemImageSlot = ({
  item,
  rarity,
  status,
}: Pick<CartItemType, 'item' | 'rarity' | 'status'>) => {
  return (
    <div
      className={cn(styles['item'], {
        [styles['item-unavailable']]: status === 'unavailable',
      })}
    >
      <ItemRarityCard
        className={styles['background']}
        size={'53'}
        variant={rarity}
      />
      <Item className={styles['item-variant']} size={'48'} variant={item} />
    </div>
  );
};

CardShoppingItemEntityMobile.ItemProperties = ({
  rarity,
  tagCategory,
}: Pick<GlobalItem, 'rarity' | 'tagCategory'>) => {
  return (
    ((Array.isArray(rarity) && rarity.length) ||
      (Array.isArray(tagCategory) && tagCategory.length)) && (
      <div className={styles['properties']}>
        {rarity && <TagRarity variant={rarity} className={styles['rarity']} />}
        {tagCategory?.map((tag, idx) => {
          return (
            <TagCategory
              className={styles['category']}
              key={`cart-shop-${tag}-${idx}`}
              variant={tag}
            />
          );
        })}
      </div>
    )
  );
};

CardShoppingItemEntityMobile.ItemPrice = observer(
  ({
    info: { price },
    status,
    variant = 'primary',
  }: Pick<CartItemType, 'info' | 'status'> & {
    variant: CardShoppingItemEntityProps['variant'];
  }) => {
    const currencySign = useStore().app.getCurrencySign;

    if (status === 'unavailable') {
      return (
        <div className={styles['price']}>
          <Typography className={styles['status']}>Not available</Typography>
        </div>
      );
    }

    return (
      <div className={cn(styles['price'], styles[variant])}>
        <Typography className={cn(styles['current-price'], styles[variant])}>
          {currencySign}
          {price.current}
        </Typography>
        {price?.old && (
          <Typography
            decoration={'line-through'}
            className={cn(styles['old-price'], styles[variant])}
          >
            {currencySign}
            {price.old}
          </Typography>
        )}
      </div>
    );
  },
);

CardShoppingItemEntityMobile.ItemInfo = ({
  info: { title, age },
  variant,
}: Pick<CartItemType, 'info' | 'status'> & {
  variant: CardShoppingItemEntityProps['variant'];
}) => {
  return <div></div>;
};
