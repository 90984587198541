'use client';
import { BottomBarCartEntityMobile } from '@entities/mobile/bottom-bar';
import { CardShoppingItemEntityMobile } from '@entities/mobile/cards';
import { SidebarFavoritesEntity } from '@entities/sidebar';
import { AdjustItemQuantityFeature } from '@features/adjust-item-quantity';
import { CartClearAllFeature } from '@features/cart-clear-all/ui';
import { CartPurchaseIsOverModal } from '@features/cart-purchase-is-over-modal';
import { AddToFavoriteShoppingItemFeatureMobile } from '@features/mobile/add-to-favorite';
import { RemoveItemFeatureMobile } from '@features/mobile/remove/ui';
import cn from 'clsx';
import { useFormik } from 'formik';
import { motion } from 'framer-motion';
import { when } from 'mobx';
import { observer } from 'mobx-react-lite';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import React from 'react';
import { ReactNode, useState } from 'react';
import { useDebounceCallback } from 'usehooks-ts';
import * as Yup from 'yup';

import { CardPaymentMethodEntity } from '@/entities';
import { Portal } from '@/shared/portal';
import { DivElementType } from '@/shared/types/common';
import { Divider, Illustration, TagQuantity, Typography } from '@/shared/ui';
import { InputAuth } from '@/shared/ui/desktop/input-auth';
import { Switch } from '@/shared/ui/desktop/switch';
import { AdditionalInfoMobile } from '@/shared/ui/mobile/additional-info';
import { BottomSheetUi } from '@/shared/ui/mobile/bottom-sheet';
import { cartStore } from '@/store/cart';
import { useStore } from '@/store/context';

import styles from './cart.module.scss';

export const CartWidgetMobile = () => {
  return <div></div>;
};

// eslint-disable-next-line react/display-name
CartWidgetMobile.Header = observer(() => {
  const cart = useStore()?.cart;
  const isCartEmpty = cart?.items.length <= 0;

  return (
    <div className={styles['cart-mobile__header']}>
      <div
        className={cn(styles['cart-mobile__header-top'], {
          [styles['no-items']]: isCartEmpty,
        })}
      >
        <div className={styles['title-block']}>
          <Typography className={styles['title']}>Cart</Typography>
          <TagQuantity quantity={cart?.totalQuantity} />
        </div>
        {!isCartEmpty && <CartClearAllFeature />}
      </div>
      {!isCartEmpty && (
        <div className={styles['cart-mobile__header-bottom']}>
          <AdditionalInfoMobile
            width={'full-w'}
            isExpandable
            variant={'success'}
          >
            Withdrawal of items is available only within 40 minutes after
            purchase! If you do not have time, the funds will be returned to
            your account!
          </AdditionalInfoMobile>
          <AdditionalInfoMobile width={'full-w'} isExpandable variant={'warn'}>
            Purchase of items is temporarily unavailable! Try it after finishing
            the technical work!
          </AdditionalInfoMobile>
        </div>
      )}
    </div>
  );
});

// eslint-disable-next-line react/display-name
CartWidgetMobile.Items = observer(() => {
  const cart = useStore()?.cart;
  const items = cart?.items;

  if (!items || items.length < 1) {
    return null;
  }

  const notificationStore = useStore()?.notifications;

  when(
    () => cartStore.hasUnavailableItems,
    () =>
      notificationStore?.addNotification({
        variant: 'warn',
        title: 'Warning!',
        subtitle: 'One or more items are no longer in stock. Check the cart.',
        delay: 3,
        id: 'has-unavailable-items-mobile',
      }),
  );

  return (
    <div className={styles['cart-mobile__items']}>
      {typeof window !== 'undefined' && window?.showCartPurchaseIsOverModal && (
        <CartPurchaseIsOverModal isMobile />
      )}
      {Array.isArray(items) &&
        items.length > 0 &&
        items.map((cartItem, idx) => {
          return (
            <React.Fragment key={`mobile-cart-item-${idx}-${cartItem.id}`}>
              <CardShoppingItemEntityMobile
                size={'l'}
                showPrice
                itemPriceSlot={
                  <CardShoppingItemEntityMobile.ItemPrice
                    variant={'primary'}
                    status={cartItem.status}
                    info={cartItem.info}
                  />
                }
                itemImageSlot={
                  <CardShoppingItemEntityMobile.ItemImageSlot
                    item={cartItem.item}
                    rarity={cartItem.rarity}
                    status={cartItem.status}
                  />
                }
                itemPropertiesSlot={
                  <CardShoppingItemEntityMobile.ItemProperties
                    rarity={cartItem.rarity}
                    tagCategory={cartItem.tagCategory}
                  />
                }
                info={cartItem.info}
                rarity={cartItem.rarity}
                bottomLeft={
                  <AdjustItemQuantityFeature
                    status={cartItem.status}
                    item={cartItem}
                  />
                }
                bottomRight={
                  <>
                    {cartItem.status !== 'unavailable' && (
                      <AddToFavoriteShoppingItemFeatureMobile
                        itemId={cartItem.propertiesSetId}
                      />
                    )}
                    <RemoveItemFeatureMobile
                      age={cartItem.info.age}
                      itemId={cartItem.propertiesSetId}
                    />
                  </>
                }
              />
              {items?.length - 1 !== idx && (
                <Divider direction={'horizontal'} />
              )}
            </React.Fragment>
          );
        })}
    </div>
  );
});

// eslint-disable-next-line react/display-name
CartWidgetMobile.BottomBar = observer(() => {
  const [sheetOpened, setSheetOpened] = useState(false);
  const cart = useStore()?.cart;

  const availablePaymentMethods = cart?.checkoutInfo?.availablePaymentMethods;
  const activePaymentMethod = cart?.checkoutInfo?.activePaymentMethod;

  const closeSheet = () => {
    setSheetOpened(false);
  };

  const isCartEmpty = cart?.items.length <= 0;

  if (isCartEmpty) {
    return null;
  }

  return (
    <>
      <Portal selector={'#navbar-portal'}>
        <BottomBarCartEntityMobile
          leftSlot={
            <BottomBarCartEntityMobile.Info
              info={{
                totalAmount: cart?.totalSumm >= 0 ? cart?.totalSumm : 0,
                quantity: cart?.totalQuantity,
              }}
            />
          }
          rightSlot={
            <BottomBarCartEntityMobile.Button
              onClick={() => {
                setSheetOpened(true);
                cart?.updateItemsInCart();
              }}
              variant={'primary'}
              width={'content'}
              as={motion.button}
              text={'Go to payment'}
              iconRight={
                <Illustration
                  id={'btn-icon'}
                  spriteName={'icons'}
                  name={'square-arrow-right'}
                />
              }
            />
          }
        />
      </Portal>

      <BottomSheetUi
        className={styles['sheet']}
        isOpen={sheetOpened}
        handleClose={closeSheet}
      >
        <BottomSheetUi.Content>
          <BottomSheetUi.Header>Payment method</BottomSheetUi.Header>

          <div className={styles['sheet-wrapper']}>
            <div className={styles['payment-methods']}>
              {Array.isArray(availablePaymentMethods) &&
                availablePaymentMethods.length > 0 &&
                availablePaymentMethods?.map((payment, idx) => {
                  return (
                    <CardPaymentMethodEntity
                      key={`${idx}-${payment?.paymentMethod}-${payment?.tagValue}`}
                      tagValue={`${payment?.tagValue}%`}
                      toggleActive={() =>
                        activePaymentMethod === payment.paymentMethod
                          ? cart?.setPaymentMethod(null)
                          : cart?.setPaymentMethod(payment.paymentMethod)
                      }
                      isActive={activePaymentMethod === payment?.paymentMethod}
                      paymentMethod={payment?.paymentMethod}
                    />
                  );
                })}
            </div>
            <div className={styles['sheet-btns']}>
              <AdditionalInfoMobile
                variant={'success'}
                icon={
                  <Illustration
                    style={{ color: 'green' }}
                    name={'tag-price'}
                    spriteName={'icons'}
                    spanTagClassName={styles.icon}
                  />
                }
              >
                Payments are protected by 3-D secure
              </AdditionalInfoMobile>

              <BottomSheetUi.Button
                text={'To pay'}
                iconLeft={
                  <Illustration
                    id={'btn-icon'}
                    name={'wallet-money-2'}
                    spriteName={'icons'}
                  />
                }
                onClick={() => {}}
              />

              <BottomSheetUi.Button
                variant={'secondary'}
                text={'Close'}
                onClick={closeSheet}
              />
            </div>

            <CartWidgetMobile.UserAgreement />
          </div>
        </BottomSheetUi.Content>
      </BottomSheetUi>
    </>
  );
});

// eslint-disable-next-line react/display-name
CartWidgetMobile.PurchaseCheckout = observer(() => {
  const cart = useStore()?.cart;
  const isUsingAccountBalance =
    cart?.checkoutInfo?.accountInfo?.isUsingAccountBalance;
  const balanceAmount = cart?.checkoutInfo?.accountInfo?.accountBalance;
  const totalAmount = cart?.totalSumm;
  const checkoutInfo = cart?.totalItems;

  const itemsCost = checkoutInfo
    .filter(item => item.type === 'item')
    .reduce((acc, curr) => acc + curr.value, 0);

  const isPromocodeApplied = !!cart?.checkoutInfo?.promocode;

  const isCartEmpty = cart?.items.length <= 0;

  const currencySign = useStore().app.getCurrencySign;

  if (isCartEmpty) {
    return null;
  }

  return (
    <div className={styles['cart-mobile__purchase-checkout']}>
      <div className={styles['partial-payment']}>
        <Typography className={styles['partial-payment__text']}>
          Pay part of the amount from my balance:
        </Typography>
        <Switch
          variant=""
          disabled={!isUsingAccountBalance}
          isChecked={isUsingAccountBalance}
          setIsChecked={() => cart?.toggleUsingAccountBalance()}
        />
      </div>
      <Divider direction={'horizontal'} />

      <div className={styles['total']}>
        {Array.isArray(checkoutInfo) &&
          checkoutInfo.length > 0 &&
          checkoutInfo.map((item, idx) => {
            return (
              <CartWidgetMobile.TotalRow
                key={`${idx}${item.title}`}
                title={item.item!}
                rightSlot={
                  <div className={styles['value-container']}>
                    <Typography
                      className={cn(styles['value'], styles['old-value'])}
                    >
                      {!!item.oldValue &&
                        `${currencySign} ${item.oldValue.toFixed(2)}`}
                    </Typography>
                    <Typography
                      className={cn(
                        styles['value'],
                        styles[
                          `total__row-price-${
                            !item.oldValue
                              ? 'neutral'
                              : item.value > item.oldValue
                                ? 'neg'
                                : 'pos'
                          }`
                        ],
                      )}
                      weight={'semi-bold'}
                    >
                      + {currencySign} {item.value.toFixed(2)}
                    </Typography>
                  </div>
                }
              />
            );
          })}

        <CartWidgetMobile.TotalRow
          title={'Commission'}
          subtitle={'3%'}
          rightSlot={
            <Typography
              className={cn(
                styles['total__row-price'],
                styles['total__row-price-neutral'],
              )}
            >
              + {currencySign} 0.56
            </Typography>
          }
        />

        {isPromocodeApplied && (
          <CartWidgetMobile.TotalRow
            title={'Promocode'}
            subtitle={'-15%'}
            rightSlot={
              <Typography
                className={cn(
                  styles['total__row-price'],
                  styles['total__row-price-pos'],
                )}
              >
                - {currencySign} 2.89
              </Typography>
            }
          />
        )}

        {isUsingAccountBalance && (
          <CartWidgetMobile.TotalRow
            title={'My balance'}
            rightSlot={
              <Typography
                className={cn(
                  styles['total__row-price'],
                  styles['total__row-price-pos'],
                )}
              >
                - $ {balanceAmount}
              </Typography>
            }
          />
        )}
      </div>
      <Divider direction={'horizontal'} />
      <CartWidgetMobile.TotalRow
        title={'Total sum:'}
        rightSlot={
          Number(totalAmount) >= 0 ? (
            <Typography
              className={cn(
                styles['total__row-price'],
                styles['total__row-price-sum'],
              )}
            >
              {currencySign} {Number(totalAmount).toFixed(2)}
            </Typography>
          ) : (
            <Typography
              className={cn(
                styles['total__row-price'],
                styles['total__row-price-sum'],
              )}
            >
              {currencySign} 0
            </Typography>
          )
        }
      />

      <CartWidgetMobile.Promocode />
    </div>
  );
});

export type CartTotalRowProps = DivElementType & {
  title: string;
  subtitle?: string;
  rightSlot?: ReactNode;
};

// eslint-disable-next-line react/display-name
CartWidgetMobile.TotalRow = ({
  title,
  subtitle,
  rightSlot,
}: CartTotalRowProps) => {
  const capitalize = (string: string) =>
    string[0].toUpperCase() + string.slice(1);

  return (
    <div className={styles['total__row']}>
      <div className={styles['total__row-right']}>
        <div className={styles['violet-circle']} />
        <div className={styles['total__row-text']}>
          <Typography className={styles['total__row-title']}>
            {capitalize(title)}
          </Typography>
          {subtitle && (
            <Typography className={styles['total__row-subtitle']}>
              {subtitle}
            </Typography>
          )}
        </div>
      </div>
      <div className={styles['total-row__left']}>{rightSlot}</div>
    </div>
  );
};

CartWidgetMobile.Empty = observer(() => {
  const cart = useStore()?.cart;
  const router = useRouter();

  if (cart?.items.length) return null;

  return (
    <SidebarFavoritesEntity
      id={'cart-empty'}
      className={styles.empty}
      topSlot={<SidebarFavoritesEntity.Image variant={'cart-no-items'} />}
      middleSlot={
        <SidebarFavoritesEntity.Info
          info={{
            title: 'Cart are empty',
            subtitle: 'Buy items using the shop!',
          }}
        />
      }
      bottomSlot={
        <SidebarFavoritesEntity.ActionButton
          text={'Open categories'}
          onClick={() => router.push('/categories')}
          variant={'primary'}
          iconRight={
            <Illustration
              id={'sidebar-fav-icon'}
              spriteName={'icons'}
              name={'square-arrow-right'}
            />
          }
        />
      }
    />
  );
});

// eslint-disable-next-line react/display-name
CartWidgetMobile.Promocode = observer(() => {
  const cart = useStore()?.cart;
  const promoCode = cart?.checkoutInfo?.promocode;

  const availablePromocodes = ['test', 'testPromo'];
  const formik = useFormik({
    initialValues: {
      promocode: '',
    },
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: Yup.object({
      promocode: Yup.string().max(15, 'Must be 15 characters or less'),
    }),
    onSubmit: values => {
      if (!values.promocode) {
        cart?.setPromocode('');
        return;
      }
      if (availablePromocodes.includes(values.promocode)) {
        cart?.setPromocode(values.promocode);
      } else {
        cart?.setPromocode('');
        formik.setFieldError('promocode', 'Invalid promocode');
      }
    },
  });

  const debounceSubmit = useDebounceCallback(formik.submitForm, 800);
  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    formik.handleChange('promocode')(event);
    debounceSubmit();
  };

  return (
    <>
      <Divider direction={'horizontal'} />

      <InputAuth
        value={formik.values.promocode}
        iconName={'ticket-sale'}
        onChange={handleChange}
        onBlur={formik.handleBlur('promocode')}
        onInput={() => formik.setFieldTouched('promocode')}
        placeholder="Promocode"
        errorMessage={formik.touched.promocode ? formik.errors.promocode : ''}
        iconLeft={
          !formik.errors.promocode &&
          promoCode &&
          promoCode === formik.values.promocode && (
            <Illustration
              name={'check-square'}
              style={{ color: '#40BB18' }}
              spriteName={'icons'}
            />
          )
        }
      />
    </>
  );
});

// eslint-disable-next-line react/display-name
CartWidgetMobile.UserAgreement = () => {
  return (
    <Typography className={styles['user-agreement']}>
      <Typography as={'span'} className={styles['user-agreement__text']}>
        By continuing you accept the terms of the{' '}
      </Typography>
      <Typography
        href={'#'}
        as={Link}
        className={styles['user-agreement__link']}
      >
        User Agreement
      </Typography>
    </Typography>
  );
};
