'use client';

import cn from 'clsx';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import React, { MouseEvent } from 'react';

import { ItemAge } from '@/shared/types/common';
import { Button, Illustration } from '@/shared/ui';
import { CartItemType } from '@/store/cart/cart';
import { useStore } from '@/store/context';

import styles from './remove-item.module.scss';

type RemoveItemFeatureProps = {
  itemId: CartItemType['propertiesSetId'] | null;
  className?: string;
  age: ItemAge;
};

export const RemoveItemFeatureMobile = observer(
  ({ itemId, className, age }: RemoveItemFeatureProps) => {
    const cart = useStore()?.cart;
    const handleActionButtonClick = (
      e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
    ) => {
      if (itemId) {
        e?.stopPropagation();
        cart?.removeFromCart(itemId, age);
      }
    };

    const btnClasses = cn(styles['btn'], className);

    return (
      <Button
        as={motion.button}
        whileTap={{ scale: 0.9 }}
        className={btnClasses}
        variant="tertiary"
        onClick={handleActionButtonClick}
        iconLeft={
          <Illustration
            name={'red-trash-bin-minimalistic'}
            spanTagClassName={styles['icon']}
            spriteName={'icons'}
            size={18}
          />
        }
      />
    );
  },
);
