import cn from 'clsx';

import { DropListSort } from '@/shared/components/drop-list-sort';

import styles from './drop-list-sort.module.scss';
import { DropListSortMobileProps } from './drop-list-sort.types';
export const DropListSortMobile = ({
  className,
  variant = 'primary',
  ...props
}: DropListSortMobileProps) => {
  const dropListMobileClasses = cn(
    styles[`container-${variant}`],
    { [styles['container-on']]: props.isOpened },
    className,
  );

  return <DropListSort.Header className={dropListMobileClasses} {...props} />;
};
