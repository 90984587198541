'use client';
import { AdjustItemQuantityFeature } from '@features/adjust-item-quantity';
import cn from 'clsx';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { MouseEvent } from 'react';

import { Button } from '@/shared/ui/button';
import { Illustration } from '@/shared/ui/illustration';
import { CartItemType } from '@/store/cart/cart';
import { useStore } from '@/store/context';

import styles from './add-to-cart.module.scss';

export type AddToCartFeatureProps = {
  item: CartItemType;
};

export const AddToCartFeatureMobile = observer(
  ({ item }: AddToCartFeatureProps) => {
    const cartStore = useStore()?.cart;
    const cartItem = cartStore?.items?.find(
      currentItem => currentItem?.propertiesSetId === item.propertiesSetId,
    );
    const handleActionButtonClick = (
      e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
    ) => {
      e?.stopPropagation();
      cartStore?.addToCart(item);
    };

    if (cartItem) {
      return (
        <AdjustItemQuantityFeature
          item={item}
          minQuantity={0}
          className={styles['quantity-container']}
        />
      );
    }

    return (
      <Button
        as={motion.button}
        whileTap={{ scale: 0.95 }}
        iconLeft={
          <Illustration
            id={'btn-icon'}
            name={'cart-plus'}
            spriteName={'icons'}
          />
        }
        width={'full-w'}
        className={cn(styles['action-btn'])}
        onClick={handleActionButtonClick}
        text={'Add to cart'}
      />
    );
  },
);
