import cn from 'clsx';

import styles from './bottom-bar-shop.module.scss';
import { BottomBarShopProps } from './bottom-bar-shop.types';

export const BottomBarShopEntityMobile = ({
  className,
  bottomSlot,
  topSlot,
}: BottomBarShopProps) => {
  const bottomBarClasses = cn(styles['container'], className);

  return (
    <div className={bottomBarClasses}>
      {bottomSlot}
      {topSlot}
    </div>
  );
};
