'use client';
import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';

import { GlobalItem } from '@/shared/types/common';
import { useStore } from '@/store/context';

export const WithShopItems = observer(
  ({
    children,
  }: {
    children: ({ items }: { items: GlobalItem[] }) => ReactNode;
  }) => {
    const shop = useStore()?.shop;

    if (!children) {
      throw new Error('Children not provided');
    }
    return children({ items: shop?.cards });
  },
);
