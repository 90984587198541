import cn from 'clsx';

import { Illustration, Item } from '@/shared/ui';
import { ItemRarityLiveFeed } from '@/shared/ui';

import styles from './card-live-feed.module.scss';
import {
  CardLiveFeedEntityItemMobileProps,
  CardLiveFeedMobileProps,
} from './card-live-feed.types';

export const CardLiveFeedEntityMobile = ({
  children,
  className,
  item,
  rarity,
  topSlot = <CardLiveFeedEntityMobile.Item rarity={rarity} item={item} />,
  handleInfoClick,
  ...props
}: CardLiveFeedMobileProps) => {
  const cardLiveFeedClasses = cn(styles['card-live-feed'], className);

  return (
    <article
      onClick={handleInfoClick}
      className={cardLiveFeedClasses}
      {...props}
    >
      <div className={cn(styles['card-live-feed--content'])}>
        <div className={cn(styles['card-live-feed--content__item'])}>
          {topSlot}
        </div>
        {/* <button onClick={handleInfoClick} className={styles['btn-info']}>
          <Illustration
            spanTagClassName={styles['icon-info']}
            spriteName={'icons'}
            style={{ color: 'var(--global-grey700)' }}
            name={'info-circle'} />
        </button>*/}
      </div>
    </article>
  );
};

// eslint-disable-next-line react/display-name
CardLiveFeedEntityMobile.Item = ({
  item,
  rarity,
}: CardLiveFeedEntityItemMobileProps) => {
  return (
    <>
      <ItemRarityLiveFeed
        size={'58'}
        className={styles['item-rarity']}
        variant={rarity}
      />
      <Item className={styles['item']} size={'96'} variant={item} />
    </>
  );
};
