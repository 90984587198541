import cn from 'clsx';
import { observer } from 'mobx-react-lite';
import { ComponentProps } from 'react';
import { useIsClient } from 'usehooks-ts';

import { Button, Typography } from '@/shared/ui';
import { useStore } from '@/store/context';

import styles from './bottom-bar-cart.module.scss';
import {
  BottomBarCartInfoProps,
  BottomBarCartProps,
} from './bottom-bar-cart.types';

export const BottomBarCartEntityMobile = ({
  leftSlot,
  rightSlot,
  className,
}: BottomBarCartProps) => {
  const bottomBarClasses = cn(styles['container'], className);

  return (
    <div className={bottomBarClasses}>
      {leftSlot}
      {rightSlot}
    </div>
  );
};

// eslint-disable-next-line react/display-name
BottomBarCartEntityMobile.Info = observer(
  ({ info, className }: BottomBarCartInfoProps) => {
    const infoClasses = cn(styles.info, className);
    const currencySign = useStore().app.getCurrencySign;

    return (
      <div className={infoClasses}>
        <Typography className={styles['info-total-amount']}>
          {currencySign} {info.totalAmount}
        </Typography>
        <Typography className={styles['info-quantity']}>
          {info.quantity}{' '}
          {info.quantity > 1 || info.quantity === 0 ? 'items' : 'item'}
        </Typography>
      </div>
    );
  },
);
// eslint-disable-next-line react/display-name
BottomBarCartEntityMobile.Button = ({
  ...props
}: ComponentProps<typeof Button>) => {
  return <Button className={styles['btn']} {...props} />;
};
