import cn from 'clsx';

import { Typography } from '@/shared/ui';
import { CategoryItemVariant } from '@/shared/ui/item/item.types';
import { CategoryItemImageByVariant } from '@/shared/ui/item/item-image-by-variant/item-image-by-variant';
import { IllustrationCardCategoryMobile } from '@/shared/ui/mobile/illustration-card-category';

import styles from './card-info-area.module.scss';
import { CardInfoAreaMobileProps } from './card-info-area.types';
export const CardInfoAreaEntityMobile = ({
  title,
  variant,
  subtitle,
  rightSlot,
  iconSlot,
  className,
  ...props
}: CardInfoAreaMobileProps) => {
  const cardInfoAreaClasses = cn(styles['container'], className);

  return (
    <article className={cardInfoAreaClasses} {...props}>
      <div className={styles['content']}>
        <Typography className={styles['title']}>{title}</Typography>

        <Typography className={styles['subtitle']}>{subtitle}</Typography>
      </div>
      {iconSlot}
      <IllustrationCardCategoryMobile
        className={styles['background']}
        variant={variant}
      />
    </article>
  );
};

// eslint-disable-next-line react/display-name
CardInfoAreaEntityMobile.Pet = ({ item }: { item: CategoryItemVariant }) => {
  return (
    <div className={styles['item-image']}>
      <CategoryItemImageByVariant
        className={styles['item']}
        size={'92'}
        variant={item}
      />
      ;
    </div>
  );
};
