'use client';
import { LogoutProps } from '@features/mobile/logout/ui/types';
import { useAuth } from '@hooks/client/use-auth';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { AdditionalInfoMobile } from '@/shared/ui/mobile/additional-info';
import { BottomSheetUi } from '@/shared/ui/mobile/bottom-sheet';

import styles from './logout.module.scss';

export const LogoutModalMobile = observer(
  ({ isOpened, close }: LogoutProps) => {
    const { logOut } = useAuth();

    const handleLogout = () => {
      logOut();
      close();
    };

    return (
      <BottomSheetUi isOpen={isOpened} handleClose={close}>
        <BottomSheetUi.Content className={styles.content}>
          <BottomSheetUi.Header>Log out</BottomSheetUi.Header>
          <AdditionalInfoMobile variant={'warn'}>
            Are you sure you want to log out of your account?
          </AdditionalInfoMobile>
          <div className={styles.buttons}>
            <BottomSheetUi.Button
              variant={'primary'}
              text={'Stay'}
              onClick={close}
            />
            <BottomSheetUi.Button
              variant={'tertiary'}
              text={'Leave'}
              onClick={handleLogout}
            />
          </div>
        </BottomSheetUi.Content>
      </BottomSheetUi>
    );
  },
);
