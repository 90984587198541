'use client';

import { ChangeCurrencyFeature } from '@features/desktop/change-currency';
import { FilterShopItemsFeatureMobile } from '@features/mobile/filters';
import { useQueryString } from '@hooks/client';
import cn from 'clsx';
import { observer } from 'mobx-react-lite';
import { nanoid } from 'nanoid';
import { usePathname, useRouter } from 'next/navigation';
import React, { useEffect, useState } from 'react';
import { useDebounceCallback, useIsClient } from 'usehooks-ts';

import { mockedData } from '@/mocks/items';
import { DropListBalance } from '@/shared/components/drop-list-balance';
import { DropListSort } from '@/shared/components/drop-list-sort';
import { Logo, Typography } from '@/shared/ui';
import { InputSearchMobile } from '@/shared/ui/mobile/input-search';
import { useStore } from '@/store/context';

import styles from './header.module.scss';

export const HeaderWidgetMobile = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles['top']}>
          <HeaderWidgetMobile.Logo />
          <div className={styles['top-right_slot']}>
            <HeaderWidgetMobile.Balance />
            {/*<DropListGame isMobile/>*/}
            <HeaderWidgetMobile.Currency />
          </div>
        </div>

        <HeaderWidgetMobile.Input />
      </div>
    </div>
  );
};
HeaderWidgetMobile.Input = observer(() => {
  const pathname = usePathname();
  const router = useRouter();
  const { createQueryString, params } = useQueryString();
  const hideSearchInput = pathname.includes('not-found');
  const [value, setValue] = useState(
    params['search'] ? params['search'][0] : '',
  );
  const [debouncedValue, setDebouncedValue] = useState(
    params['search'] ? params['search'][0] : '',
  );
  const historySearch = useStore()?.header;
  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const setSearchParam = (value: string) => {
    const shouldRedirectToShop =
      RegExp('^\\/[a-zA-Z]{2}/categories$').test(pathname) ||
      RegExp('^\\/[a-zA-Z]{2}$').test(pathname) ||
      RegExp('^\\/([a-zA-Z]{2})\\/shop\\/.*$').test(pathname);

    router.push(
      createQueryString({
        pathname: shouldRedirectToShop ? '/shop' : pathname,
        name: 'search',
        value: value,
        asMultipleParams: false,
      }),
    );
  };

  const selectItem = (text: string) => {
    setValue(text);
    setDebouncedValue(text);
    setSearchParam(text);
  };

  const debouncedSetSearchParam = useDebounceCallback(setSearchParam, 1000);
  const handleDebouncedValue = useDebounceCallback(setDebouncedValue, 500);

  useEffect(() => {
    value && handleDebouncedValue(value);
  }, [value]);

  return !hideSearchInput ? (
    <>
      <InputSearchMobile
        handleBlur={() => {
          if (value) {
            historySearch?.setHistoryItem('recently', { id: nanoid(), value });
            setSearchParam(value);
          }
        }}
        handleKeyDown={e => {
          if (e.key === 'Enter') {
            setSearchParam(value);
          }
        }}
        onSelectItem={selectItem}
        value={value}
        handleResetValue={() => setValue('')}
        onSubmit={() => debouncedSetSearchParam(debouncedValue)}
        handleChange={onChangeHandler}
      >
        <InputSearchMobile.SearchHistory
          inputValue={value}
          onSelectItem={selectItem}
        />
      </InputSearchMobile>
      <div id={'header-portal'} />
    </>
  ) : null;
});
HeaderWidgetMobile.Logo = observer(() => {
  return <Logo />;
});

HeaderWidgetMobile.Balance = observer(() => {
  const isClient = useIsClient();
  const isAuth = !!useStore().authorization.userInfo;

  return isAuth && isClient ? <DropListBalance isMobile /> : null;
});

HeaderWidgetMobile.Currency = observer(() => {
  const isClient = useIsClient();
  const isAuth = !!useStore().authorization.userInfo;

  return !isAuth && isClient ? <ChangeCurrencyFeature isMobile /> : null;
});

// eslint-disable-next-line react/display-name
HeaderWidgetMobile.Alternative = observer(
  ({ className }: { className?: string }) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const router = useRouter();
    const navHistory = useStore().app;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { createQueryString, params } = useQueryString();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const pathname = usePathname();

    return (
      <div className={cn(styles['header-alternative'], className)}>
        <div className={styles['header-alternative__content']}>
          <div id={'header-alt-top'} className={styles['top']}>
            <InputSearchMobile.HeaderWrapper className={styles['no-shadow']}>
              <InputSearchMobile.BackButton
                onClick={() => {
                  router.push(navHistory?.prevPage.url);
                }}
              />
              <HeaderWidgetMobile.Input />
            </InputSearchMobile.HeaderWrapper>
          </div>
          <div id={'header-alt-bottom'} className={styles['bottom']}>
            {params['search'] && (
              <Typography className={styles['search-results']}>
                {`By searching for "${params['search']}" found: `}
                <Typography as={'span'} className={styles['value']}>
                  {`${mockedData.length} items`}
                </Typography>
              </Typography>
            )}
            <div className={styles['drop-list-wrapper']}>
              <DropListSort
                isMobile
                handleSelectSortType={item => {
                  router.push(
                    createQueryString({
                      pathname: pathname,
                      name: 'sort',
                      value: item.filter,
                      asMultipleParams: false,
                    }),
                  );
                }}
              />
              <FilterShopItemsFeatureMobile />
            </div>
          </div>
        </div>
      </div>
    );
  },
);
