'use client';
import { observer } from 'mobx-react-lite';
import { useIsClient } from 'usehooks-ts';

import { ButtonFavoriteMobile } from '@/shared/ui/mobile/button-favorite/button-favorite';
import { useStore } from '@/store/context';
export type AddToFavoriteFeatureProps = {
  variant: 'primary' | 'secondary';
  id: string | number;
};
export const AddToFavoriteFeatureMobile = observer(
  ({ variant, id }: AddToFavoriteFeatureProps) => {
    const favorites = useStore().favorite;
    const isInFavorite = favorites.favoriteIds.includes(id);
    const isClient = useIsClient();

    if (!isClient) {
      return null;
    }

    return (
      <ButtonFavoriteMobile
        variant={variant}
        onPress={() => favorites.toggleFavorite(id)}
        isLiked={isInFavorite}
      />
    );
  },
);
