import { TagCategoryVariants } from '@/shared/ui/tag-category/tag-category.types';

export const tabs1: { name: string; icon: TagCategoryVariants }[] = [
  { name: 'Neon', icon: 'neon' },
  { name: 'Mega-Neon', icon: 'mega-neon' },
  { name: 'Fly', icon: 'fly' },
  { name: 'Ride', icon: 'ride' },
];

export const tabs2: { name: string }[] = [
  { name: 'Egg' },
  { name: 'Potion' },
  { name: 'Pet' },
  { name: 'Transport' },
];
