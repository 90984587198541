'use client';
import React, { useState } from 'react';

import {
  Description,
  Illustration,
  IllustrationModal,
  ModalContent,
  ModalWrapper,
} from '@/shared/ui';
import { ButtonCode } from '@/shared/ui/button-code';
import { AdditionalInfo } from '@/shared/ui/desktop/additional-info';
import { AdditionalInfoMobile } from '@/shared/ui/mobile/additional-info';

import styles from './cart-purchase-is-over-modal.module.scss';
export const CartPurchaseIsOverModal = ({
  isMobile,
}: {
  isMobile?: boolean;
}) => {
  const [isOpened, setIsOpened] = useState(true);

  return (
    <ModalWrapper
      close={() => setIsOpened(false)}
      isMobile={isMobile}
      isOpened={isOpened}
    >
      <CartPurchaseIsOverModal.Content
        handleClose={() => setIsOpened(false)}
        isMobile={false}
      />
    </ModalWrapper>
  );
};

CartPurchaseIsOverModal.Content = ({
  isMobile,
  handleClose,
}: {
  isMobile: boolean;
  handleClose: () => void;
}) => {
  return (
    <ModalContent
      className={styles['container']}
      topRightSlot={<ModalContent.CloseButton onClick={handleClose} />}
    >
      <IllustrationModal variant={'purchase-is-over'} />
      <ModalContent.Title
        title={'Oops!'}
        subtitle={'We apologize for the inconvenience'}
      />

      <ModalContent.Body>
        <Description
          message={'The product that you wanted to purchase is over'}
        />
        <Description
          message={'The purchase amount has been credited to your balance'}
        />
        <ButtonCode
          code={'SORRY10'}
          icon={
            <Illustration
              style={{ color: '#6941C6' }}
              name={'ticket-sale'}
              spriteName={'icons'}
            />
          }
        />
        {!isMobile ? (
          <AdditionalInfo
            align={'center'}
            width={'full-w'}
            variant={'success'}
            icon={
              <AdditionalInfo.DefaultIcon
                variant={'success'}
                name={'info-square'}
              />
            }
          >
            Please accept this 10% discount promo code as our apology. It&apos;s
            valid for 7 days
          </AdditionalInfo>
        ) : (
          <AdditionalInfoMobile
            width={'full-w'}
            variant={'success'}
            icon={
              <AdditionalInfoMobile.DefaultIcon
                variant={'success'}
                name={'info-square'}
              />
            }
          >
            Please accept this 10% discount promo code as our apology. It&apos;s
            valid for 7 days
          </AdditionalInfoMobile>
        )}
      </ModalContent.Body>
      <ModalContent.Footer>
        <ModalContent.Button text={'Ok'} onClick={handleClose} />
      </ModalContent.Footer>
    </ModalContent>
  );
};
