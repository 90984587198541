'use client';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { Illustration } from '@/shared/ui';
import { Button } from '@/shared/ui/button';
import { useStore } from '@/store/context';

import styles from './clear-all.module.scss';

export const CartClearAllFeature = observer(() => {
  const cart = useStore()?.cart;

  return (
    <Button
      width={'content'}
      disabled={cart?.items?.length === 0}
      className={styles['clear-btn']}
      iconLeft={
        <Illustration
          name={'red-trash-bin-minimalistic'}
          spriteName={'icons'}
        />
      }
      variant={'tertiary'}
      onClick={() => cart?.clearAll()}
      text={'Clear all'}
    />
  );
});
