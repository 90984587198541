import cn from 'clsx';

import { Illustration } from '@/shared/ui';

import styles from './illustration-card-category.module.scss';
import { IllustrationCardCategoryProps } from './illustration-card-category.types';

export const colorsByVariant = {
  blue: 'var(--global-blue300)',
  purple: 'var(--global-purple300)',
  pink: 'var(--global-pink300)',
  green: 'var(--global-green300)',
  black: 'var(--global-black100)',
  orange: 'var(--global-orange300)',
  red: 'var(--global-red100)',
} as const;
export const IllustrationCardCategoryMobile = ({
  variant,
  className,
  style,
  backgroundColor,
  ...props
}: IllustrationCardCategoryProps) => {
  const illustrationCardCategoryClasses = cn(
    styles['illustration-card-category'],
    className,
  );

  return (
    <Illustration
      style={{
        color: backgroundColor ? backgroundColor : colorsByVariant[variant],
        ...style,
      }}
      spanTagClassName={illustrationCardCategoryClasses}
      name={'illustration-card-categories'}
    />
  );
};
