import Image from 'next/image';
import React from 'react';

import { Plate } from '@/shared/ui/desktop/plate';

import COLLABORATION_BLOGGERS_320 from '../../../../public/assets/marketing-banners/blogers/collaboration-bloggers-320.webp';
import COLLABORATION_BLOGGERS_428 from '../../../../public/assets/marketing-banners/blogers/collaboration-bloggers-428.webp';
import PETS_GIVEAWAY_320 from '../../../../public/assets/marketing-banners/pets-giveaway/pets-giveaway-320.webp';
import PETS_GIVEAWAY_428 from '../../../../public/assets/marketing-banners/pets-giveaway/pets-giveaway-428.webp';
import CONSTANT_PROMOTION_320 from '../../../../public/assets/marketing-banners/promotions/constant-promotions-320.webp';
import CONSTANT_PROMOTION_428 from '../../../../public/assets/marketing-banners/promotions/constant-promotions-428.webp';
import SOCIAL_BOOST_320 from '../../../../public/assets/marketing-banners/social/social-boost-320.webp';
import SOCIAL_BOOST_428 from '../../../../public/assets/marketing-banners/social/social-boost-428.webp';
import WEBSITE_CONCEPT_1_320 from '../../../../public/assets/marketing-banners/website-concept/website-concept-1-320.webp';
import WEBSITE_CONCEPT_1_428 from '../../../../public/assets/marketing-banners/website-concept/website-concept-1-428.webp';
import WEBSITE_CONCEPT_2_320 from '../../../../public/assets/marketing-banners/website-concept/website-concept-2-320.webp';
import WEBSITE_CONCEPT_2_428 from '../../../../public/assets/marketing-banners/website-concept/website-concept-2-428.webp';
import WEBSITE_CONCEPT_3_320 from '../../../../public/assets/marketing-banners/website-concept/website-concept-3-320.webp';
import WEBSITE_CONCEPT_3_428 from '../../../../public/assets/marketing-banners/website-concept/website-concept-3-428.webp';
import styles from './banner-image.module.scss';
import { BannerImageProps, BannersByVariant } from './banner-image.types';

const bannersByVariant: BannersByVariant = {
  concept1: {
    small: WEBSITE_CONCEPT_1_320,
    large: WEBSITE_CONCEPT_1_428,
  },
  concept2: {
    small: WEBSITE_CONCEPT_2_320,
    large: WEBSITE_CONCEPT_2_428,
  },
  concept3: {
    small: WEBSITE_CONCEPT_3_320,
    large: WEBSITE_CONCEPT_3_428,
  },
  'social-boost': {
    small: SOCIAL_BOOST_320,
    large: SOCIAL_BOOST_428,
  },
  'constant-promotion': {
    small: CONSTANT_PROMOTION_320,
    large: CONSTANT_PROMOTION_428,
  },
  'bloggers-collaboration': {
    small: COLLABORATION_BLOGGERS_320,
    large: COLLABORATION_BLOGGERS_428,
  },
  'pets-giveaway': {
    small: PETS_GIVEAWAY_320,
    large: PETS_GIVEAWAY_428,
  },
};

export const BannerImageEntityMobile = ({
  variant,
  size,
  className,
}: BannerImageProps) => {
  if (!size) return null;

  return (
    <Image
      loading={'eager'}
      className={className}
      src={bannersByVariant[variant][size]}
      priority
      fetchPriority={'high'}
      placeholder="blur"
      blurDataURL="./"
      fill
      quality={100}
      alt={'concept'}
      style={{
        borderRadius: 'inherit',
        objectFit: 'cover',
      }}
    />
  );
};
