import { motion } from 'framer-motion';

import { Button } from '@/shared/ui';

import styles from './reset.module.scss';

export const ResetAllFiltersFeatureMobile = () => {
  return (
    <Button
      as={motion.button}
      whileTap={{ scale: 0.98 }}
      variant={'secondary'}
      text={'Reset all filters'}
      className={styles['feature']}
    />
  );
};
