import cn from 'clsx';

import { Illustration, Typography } from '@/shared/ui';

import styles from './card-item-page.module.scss';
import { CardItemPageEntityProps } from './card-item-page.types';
export const CardItemPageEntityMobile = ({
  className,
  variant = 'default',
  rightSlot,
  topLeftText,
  topRightText,
  topRightIcon,
  bottomText,
  bottomIcon,
  ...props
}: CardItemPageEntityProps) => {
  const cardItemClasses = cn(
    styles['container'],
    styles[`variant--${variant}`],
    className,
  );

  return (
    <article className={cardItemClasses} {...props}>
      <div className={styles['left-slot']}>
        <div className={styles['price-info']}>
          <div className={styles['top']}>
            {topLeftText && (
              <Typography className={styles['left-text']}>
                {topLeftText}
              </Typography>
            )}
            {topRightText && (
              <div className={styles['right']}>
                <Typography className={styles['right-text']}>
                  {topRightText}
                </Typography>
                <div className={styles['right-icon']}>{topRightIcon}</div>
              </div>
            )}
          </div>
          {bottomText && (
            <div className={styles['bottom']}>
              <Typography className={styles['text']}>{bottomText}</Typography>
              <div className={styles['icon']}>{bottomIcon}</div>
            </div>
          )}
        </div>
      </div>
      {rightSlot && <div className={styles['right-slot']}>{rightSlot}</div>}
    </article>
  );
};
