'use client';
import { NavigationBottomEntityMobile } from '@entities/mobile/navigation-bottom';
import { useProtectedRouter } from '@hooks/client/use-protected-router';
import cn from 'clsx';
import { observer } from 'mobx-react-lite';
import { usePathname, useRouter } from 'next/navigation';
import React, { ReactNode } from 'react';
import { useIsClient } from 'usehooks-ts';

import { Avatar } from '@/shared/ui';
import { useStore } from '@/store/context';

export const NavigationWidgetMobile = observer(() => {
  const pathname = usePathname();
  const router = useProtectedRouter();
  const cart = useStore()?.cart;
  const totalAmount = cart?.totalSumm >= 0 ? cart?.totalSumm : 0;
  const auth = useStore().authorization;
  const isClient = useIsClient();
  if (pathname.includes('/not-found')) {
    return null;
  }

  return (
    <NavigationBottomEntityMobile>
      <NavigationBottomEntityMobile.Item
        onClick={() => router.push('/')}
        isActive={RegExp('^\\/[a-z]{2}$').test(pathname)}
        topSlot={<NavigationBottomEntityMobile.Icon icon={'home-smile'} />}
        bottomSlot={
          <NavigationBottomEntityMobile.Title>
            Home
          </NavigationBottomEntityMobile.Title>
        }
      />

      <NavigationBottomEntityMobile.Item
        onClick={() => router.push('/categories')}
        isActive={RegExp('^\\/[a-z]{2}\\/categories(\\/[a-z]*)?$').test(
          pathname,
        )}
        topSlot={<NavigationBottomEntityMobile.Icon icon={'widget-2'} />}
        bottomSlot={
          <NavigationBottomEntityMobile.Title>
            Categories
          </NavigationBottomEntityMobile.Title>
        }
      />

      <NavigationBottomEntityMobile.Item
        onClick={() => router.push('/cart')}
        isActive={RegExp('^\\/[a-z]{2}/cart$').test(pathname)}
        topSlot={<NavigationBottomEntityMobile.Icon icon={'cart-5'} />}
        withBadge={isClient && totalAmount > 0}
        badgeCount={cart?.totalQuantity}
        bottomSlot={
          <NavigationBottomEntityMobile.Title>
            <WithCurrency>{totalAmount ?? 0}</WithCurrency>
          </NavigationBottomEntityMobile.Title>
        }
      />
      <NavigationBottomEntityMobile.Item
        onClick={() => router.push('/inventory')}
        isActive={RegExp('^\\/[a-z]{2}/inventory$').test(pathname)}
        topSlot={<NavigationBottomEntityMobile.Icon icon={'bag-3'} />}
        bottomSlot={
          <NavigationBottomEntityMobile.Title>
            Inventory
          </NavigationBottomEntityMobile.Title>
        }
      />
      <NavigationBottomEntityMobile.Item
        onClick={() =>
          !auth.userInfo ? auth.openSignIn() : router.push('/profile')
        }
        isActive={pathname.includes('profile')}
        topSlot={
          auth.userInfo ? (
            <Avatar
              isMobile
              src={
                auth.userInfo.avatarUrl ||
                'https://i.pravatar.cc/150?u=a042581f4e29026024d'
              }
              onClick={() => {}}
            />
          ) : (
            <NavigationBottomEntityMobile.Icon icon={'user'} />
          )
        }
        bottomSlot={
          <NavigationBottomEntityMobile.Title>
            Profile
          </NavigationBottomEntityMobile.Title>
        }
      />
    </NavigationBottomEntityMobile>
  );
});

const WithCurrency = observer(({ children }: { children: ReactNode }) => {
  const currencySign = useStore().app.getCurrencySign;

  const isClient = useIsClient();
  const isHydrated = useStore().app.isHydrated && isClient;

  return isHydrated ? `${currencySign} ${children}` : '$ 0';
});
